import React, { Component } from "react";
import Logo from "../../images/logo.webp";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: false,
    };
  }

  handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    this.setState({ isScrolled: scrollTop > 80 });
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  preventClick = (e) => {
    e.preventDefault();
  };

  render() {
    const { isScrolled } = this.state;

    // Define only position-related transition for the navbar
    const navbarStyle = {
      position: "fixed",
      top: 0,
      width: "100%",
      zIndex: 1000,
      transition: "top 0.4s ease", // Smooth transition for the fixed position
    };

    // Spacer div style to prevent overlap
    const spacerStyle = {
      height: "80px", // Adjust this height to match your navbar height
    };

    return (
      <>
        {/* Navbar */}
        <header id="header" style={navbarStyle} className="wpo-site-header">
          <nav className="navigation navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                  <div className="mobail-menu">
                    <MobileMenu />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-6">
                  <div className="navbar-header">
                    <Link onClick={this.ClickHandler} className="navbar-brand" to="/home">
                      <img src={Logo} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-7 col-md-1 col-1">
                  <div id="navbar" className="collapse navbar-collapse navigation-holder">
                    <button className="menu-close">
                      <i className="ti-close"></i>
                    </button>
                    <ul className="nav navbar-nav mb-2 mb-lg-0">
                      <li><Link onClick={this.ClickHandler} to="/">Home</Link></li>
                      <li><Link onClick={this.ClickHandler} to="/about">About us</Link></li>
                      <li className="menu-item-has-children">
                        <Link to="/" onClick={this.preventClick} className="nav-link">
                          Services
                        </Link>
                        <ul className="sub-menu">
                        <li className="menu-item-has-children">
                        <Link to="/" onClick={this.preventClick} className="nav-link">
                          Training Programs
                        </Link>
                        <ul className="sub-sub-menu">
                          <li><Link onClick={this.ClickHandler} to="/trainingprograms2024">Training Programs 2024</Link></li>
                          <li><Link onClick={this.ClickHandler} to="/trainingprograms2023">Training Programs 2023</Link></li>
                          <li><Link onClick={this.ClickHandler} to="/trainingprograms2022">Training Programs 2022</Link></li>
                        </ul>
                      </li>
                          <li><Link onClick={this.ClickHandler} to="/forums">Forums</Link></li>
                          <li><Link onClick={this.ClickHandler} to="/conferences">Conferences</Link></li>
                      </ul>

                      </li>
                      <li><Link onClick={this.ClickHandler} to="/media">Media</Link></li>
                      <li className="menu-item-has-children">
                        <Link onClick={this.ClickHandler} to="/clients">Our Clients</Link>
                        <ul className="sub-menu">
                          <li><Link onClick={this.ClickHandler} to="/clients">Clients</Link></li>
                          <li><Link onClick={this.ClickHandler} to="/testimonials">Testimonials</Link></li>
                        </ul>
                      </li>
                      <li className="menu-item-has-children">
                        <Link onClick={this.ClickHandler} to="/trainingplan">Training Plans</Link>
                        <ul className="sub-menu">
                          <li><Link onClick={this.ClickHandler} to="/trainingplan">Training Plan 2024</Link></li>
                          <li>
                            <a
                              href={require("../../files/Mafaz_Training_plan_2023.pdf")}
                              download="Mafaz_Training_plan_2023 .pdf"
                            >
                              Training Plan 2023
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li><Link onClick={this.ClickHandler} to="/contact">Contact us</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-2">
                  <div className="header-right">
                    {/* Removed search-related code here */}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {/* Spacer div to push content down */}
        <div style={spacerStyle} />

        {/* Main content goes here */}
        <div>
          {/* Your page content */}
        </div>
      </>
    );
  }
}

export default Header;
