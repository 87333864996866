import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Trainigprograms2023 from '../../components/Trainigprograms2023';

import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const TrainingPrograms2023 =() => {
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'Training Programs 2023'} pagesub={'Services'}/> 
            <Trainigprograms2023/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPrograms2023;