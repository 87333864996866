import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
    },
    {
        id: 2,
        title: 'About us',
        link: '/about',
    },
    {
        id: 3,
        title: 'Services',
        submenu: [
            {
                id: 33,
                title: 'Training Programs',
                link: '/trainingprograms',
                submenu: [
                    {
                        id: 331,
                        title: 'Training Programs 2024',
                        link: '/trainingprograms2024',
                    },
                    {
                        id: 332,
                        title: 'Training Programs 2023',
                        link: '/trainingprograms2023',
                    },
                    {
                        id: 333,
                        title: 'Training Programs 2022',
                        link: '/trainingprograms2022',
                    },
                ],
            },
            {
                id: 34,
                title: 'Forums',
                link: '/forums',
            },
            {
                id: 35,
                title: 'Conferences',
                link: '/conferences',
            },
        ],
    },
    {
        id: 4,
        title: 'Media',
        link: '/media',
    },
    {
        id: 7,
        title: 'Our Clients',
        submenu: [
            {
                id: 71,
                title: 'Clients',
                link: '/clients',
            },
            {
                id: 75,
                title: 'Testimonials',
                link: '/testimonials',
            },
        ],
    },
    {
        id: 100,
        title: 'Training Plans',
        link: '/trainingplan',
        submenu: [
            {
                id: 71,
                title: 'Training Plans 2024',
                link: '/trainingplan',
            },
            {
                id: 75,
                title: 'Training Plans 2023',
                link: require('../../files/Mafaz_Training_plan_2023.pdf'),
                download: true,
                filename: 'Mafaz_Training_plan_2023.pdf',
            },
        ],
    },
    {
        id: 88,
        title: 'Contact us',
        link: '/contact',
    },
];

export default class MobileMenu extends Component {
    state = {
        isMenuShow: false,
        openMenus: {}, // Track open/close state of menus and submenus
    };

    menuHandler = () => {
        this.setState({
            isMenuShow: !this.state.isMenuShow,
        });
    };

    toggleMenu = (id) => {
        this.setState((prevState) => ({
            openMenus: {
                ...prevState.openMenus,
                [id]: !prevState.openMenus[id], // Toggle specific menu/submenu
            },
        }));
    };

    render() {
        const { isMenuShow, openMenus } = this.state;

        const ClickHandler = () => {
            window.scrollTo(10, 0);
        };

        return (
            <div>
                <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
                    <div className="menu-close">
                        <div className="clox" onClick={this.menuHandler}>
                            <i className="ti-close"></i>
                        </div>
                    </div>

                    <ul className="responsivemenu">
                        {menus.map((item) => (
                            <li key={item.id}>
                                {item.submenu ? (
                                    <>
                                        <p onClick={() => this.toggleMenu(item.id)}>
                                            {item.title}
                                            <i
                                                className={`fa fa-angle-right ${
                                                    openMenus[item.id] ? 'active' : ''
                                                }`}
                                            ></i>
                                        </p>
                                        <Collapse isOpen={!!openMenus[item.id]}>
                                            <Card>
                                                <CardBody>
                                                    <ul>
                                                        {item.submenu.map((submenu) => (
                                                            <li key={submenu.id}>
                                                                {submenu.submenu ? (
                                                                    <>
                                                                        <p
                                                                            onClick={() =>
                                                                                this.toggleMenu(submenu.id)
                                                                            }
                                                                        >
                                                                            {submenu.title}
                                                                            <i
                                                                                className={`fa fa-angle-right ${
                                                                                    openMenus[submenu.id]
                                                                                        ? 'active'
                                                                                        : ''
                                                                                }`}
                                                                            ></i>
                                                                        </p>
                                                                        <Collapse
                                                                            isOpen={
                                                                                !!openMenus[submenu.id]
                                                                            }
                                                                        >
                                                                            <Card>
                                                                                <CardBody>
                                                                                    <ul>
                                                                                        {submenu.submenu.map(
                                                                                            (nested) => (
                                                                                                <li
                                                                                                    key={
                                                                                                        nested.id
                                                                                                    }
                                                                                                >
                                                                                                    <Link
                                                                                                        onClick={
                                                                                                            ClickHandler
                                                                                                        }
                                                                                                        to={
                                                                                                            nested.link
                                                                                                        }
                                                                                                    >
                                                                                                        {
                                                                                                            nested.title
                                                                                                        }
                                                                                                    </Link>
                                                                                                </li>
                                                                                            )
                                                                                        )}
                                                                                    </ul>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Collapse>
                                                                    </>
                                                                ) : submenu.download ? (
                                                                    <a
                                                                        href={submenu.link}
                                                                        download={
                                                                            submenu.filename
                                                                        }
                                                                        className="download-link"
                                                                    >
                                                                        {submenu.title}
                                                                    </a>
                                                                ) : (
                                                                    <Link
                                                                        onClick={ClickHandler}
                                                                        to={submenu.link}
                                                                    >
                                                                        {submenu.title}
                                                                    </Link>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </CardBody>
                                            </Card>
                                        </Collapse>
                                    </>
                                ) : (
                                    <Link onClick={ClickHandler} to={item.link}>
                                        {item.title}
                                    </Link>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="showmenu" onClick={this.menuHandler}>
                    <button type="button" className="navbar-toggler open-btn">
                        <span className="icon-bar first-angle"></span>
                        <span className="icon-bar middle-angle"></span>
                        <span className="icon-bar last-angle"></span>
                    </button>
                </div>
            </div>
        );
    }
}
