import React, { useState, useEffect } from 'react';

// Import images

//Preparatory Program for Certified Internal Auditor (CIA) Certification
import prjImg1 from '../../images/training_programs_2023/Preparatory Program for Certified Internal Auditor (CIA) Certification/image1.webp';
import prjImg2 from '../../images/training_programs_2023/Preparatory Program for Certified Internal Auditor (CIA) Certification/image2.webp';

//Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards
import prjImg3 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image1.webp';
import prjImg4 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image2.webp';
import prjImg5 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image3.webp';
import prjImg6 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image4.webp';
import prjImg7 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image5.webp';
import prjImg18 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image6.webp';
import prjImg19 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image7.webp';
import prjImg20 from '../../images/training_programs_2023/Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards/image8.webp';

//Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements
import prjImg8 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image1.webp';
import prjImg9 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image2.webp';
import prjImg10 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image3.webp';
import prjImg11 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image4.webp';
import prjImg21 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image5.webp';
import prjImg22 from '../../images/training_programs_2023/Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements/image6.webp';

//Marketing Communication Course
import prjImg12 from '../../images/training_programs_2023/Marketing Communication Course/image1.webp';
import prjImg13 from '../../images/training_programs_2023/Marketing Communication Course/image2.webp';
import prjImg24 from '../../images/training_programs_2023/Marketing Communication Course/image3.jpg';
import prjImg25 from '../../images/training_programs_2023/Marketing Communication Course/image4.jpg';


//Financial Accounting From Income Statement Perspective
import prjImg15 from '../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image1.webp';
import prjImg16 from '../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image2.webp';
import prjImg17 from '../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image3.webp';
import prjImg23 from '../../images/training_programs_2023/Financial Accounting From Income Statement Perspective/image4.jpg';

// Project data array
const projects = [
    {
        images: [prjImg1, prjImg2],
        title: "Preparatory Program for Certified Internal Auditor (CIA) Certification",
        description: [
            "The Certified Internal Auditor (CIA) designation is the most globally recognized certification for internal auditors and the standard through which individuals demonstrate their professional competence in internal auditing. Obtaining the CIA certification is a significant achievement and a professional asset for internal auditors at all stages of their careers."
        ],
        sections: [
            {
                header: "Key Benefits",
                bulletPoints: [
                    "Distinguishes the certificate holder",
                    "Enhances knowledge of best professional practices",
                    "Confirms professional competence",
                    "Establishes a foundation for ongoing improvement and advancement",
                    "Helps gain credibility and respect in the field",
                    "Opens more advancement opportunities",
                    "Increases potential for income growth by up to 40%",
                    "Demonstrates commitment to the profession",
                    "Enhances internal auditing skills and knowledge",
                    "Builds confidence in professional expertise"
                ]
            }
        ],
        location: "Muscat, Sultanate of Oman",
        date: "26 Feb 2023",
        duration: "5 Days"
    },
    {
        images: [prjImg4,prjImg3, prjImg5,prjImg6,prjImg7,prjImg18,prjImg19,prjImg20],
        title: "Practical Procedures for Implementing Administrative, Operational, and Analytical Audits According to International Standards",
        description: [
            "This program is designed to empower employees in audit units to enhance their capabilities in administrative and operational (performance) auditing. It incorporates analytical procedures that enable auditors to reach high-quality results to report to and guide senior management. This contributes to raising the organization's standards and improving audit quality. Participants will also gain an understanding of the mechanisms for thoroughly inspecting all activities and operations conducted within various organizations or governmental entities. ",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "1 Oct 2023",
        duration: "10 Days",
    },
    {
        images: [prjImg8, prjImg9,prjImg10,prjImg11,prjImg21,prjImg22],
        title: "Financial Analysis and Its Role in Detecting Fraud and Manipulation in Financial Statements",
        description: [
            "This program sheds light on the concept and types of financial statements, the concept of accounting fraud and manipulation, and earnings management. It explores the role of financial analysis in detecting fraud and manipulation in financial statements, and how earnings management can be used to commit fraud and manipulation. Additionally, it addresses the key challenges in detecting accounting fraud and manipulation. The program also covers money laundering methods and indicators, as well as ways to document illicit income. It includes a set of applications, practical cases, and workshops tailored to the topics discussed.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "20 Aug 2023",
        duration: "5 Days",
    },
    {
        images: [prjImg13, prjImg12,prjImg24,prjImg25],
        title: "Marketing Communication Course",
        description: [
            "Marketing Communication: Mixing Media for Greatest Impact is a comprehensive training course designed to transform your approach to marketing communication. In today's evolving landscape, where traditional advertising and promotion methods are becoming less effective, it is essential to harness the power of multiple channels to engage, influence, and persuade your target audiences. This course combines elements of public relations and marketing to equip you with the skills to sell products, win customers, and foster loyalty.",
            "You will learn how to leverage social media and other emerging channels effectively. With consumers now dictating how and when they receive information, marketers must possess a deep understanding of designing and executing marketing plans that resonate with audiences, as well as measuring the outcomes. Led by a marketing expert. This course will guide you in researching your marketing communication needs, crafting a compelling marketing plan, and employing PR, social media, and email marketing strategies. You will also gain insights into campaign launch techniques and tracking methods through a measurement dashboard.",
            "Marketing communication is an integral and intricate aspect of a company's marketing efforts. It encompasses various elements such as advertising, direct marketing, branding, packaging, online presence, printed materials, PR activities, sales presentations, sponsorships, trade show appearances, and more. By enrolling in this course, you will revolutionize your understanding and application of marketing communication techniques, ultimately elevating your professional prowess in this field.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "18 Sep 2023",
        duration: "2 Days",
    },
    {
        images: [prjImg16, prjImg15, prjImg17,prjImg23],
        title: "Financial Accounting From Income Statement Perspective",
        description: [
            "This covers financial accounting basics, focusing on inventory systems, merchandising, and valuation methods like FIFO, LIFO, and average cost. It includes income statement analysis, inventory pricing, and estimation techniques such as the retail and gross profit methods."
        ],
        sections: [
            {
                header: "Topics Covered",
                bulletPoints: [
                    "Memorize and apply basic financial accounting skills.",
                    "Identify management issues related to merchandising businesses.",
                    "Compare income statements for service and merchandising concerns, and define the components of the merchandising income statement.",
                    "Distinguish between periodic and perpetual inventory systems, and explain the importance of physical inventory.",
                    "Record transactions for sales and purchases under periodic and perpetual inventory systems.",
                    "Apply sales and purchase discounts to merchandising transactions.",
                    "Identify and explain management issues in inventory accounting.",
                    "Define inventory cost and relate it to goods flow and cost flow.",
                    {
                        mainPoint: "Calculate inventory pricing using the following methods:",
                        subPoints: [
                            "Specific identification method.",
                            "Average-cost method.",
                            "First-in, first-out (FIFO) method.",
                            "Last-in, first-out (LIFO) method."
                        ]
                    },
                    "Apply the perpetual inventory system for inventory pricing at cost.",
                    "Assess the effects of inventory methods and misstatements on income determination and taxes.",
                    "Apply the lower-of-cost-or-market (LCM) rule to inventory valuation.",
                    {
                        mainPoint: "Estimate ending inventory costs using the following methods:",
                        subPoints: [
                            "Retail inventory method.",
                            "Gross profit method."
                        ]
                    }
                ]
            }
        ],
        location: "Muscat, Sultanate of Oman",
        date: "22 Oct 2023",
        duration: "5 Days",
    },
    
    
    // {
    //     images: [prjImg5, prjImg2],
    //     title: "Technical Inspection (VAT, Excise Tax, and Refund)",
    //     description: [
    //         "This program aims to introduce participants to contemporary tax systems, their types, and the principles and rules for imposing various types of taxes. It seeks to develop participants' accounting skills in the field of tax accounting and tax settlement, including methods for determining tax bases, deductible costs for each type, and distinguishing between tax exemptions and non-taxable status.",
    //         "The program also aims to equip participants with skills in conducting tax audits of files, accounts, and taxable activities, enhancing their abilities in applying VAT, and guiding them on registration for VAT purposes. Additionally, it aims to develop participants' skills in reviewing periodic tax reports and declarations in light of tax regulations and laws according to the requirements of the tax administration responsible for tax collection.",
    //         "Through the program, participants are introduced to the concept of tax evasion, its motivations, and methods, along with anti-evasion strategies. It also seeks to promote tax awareness and culture among accountants and those interested in VAT matters, reinforcing the principle of compliance with tax legislation and fostering cooperation and partnership with various sectors.",

    //     ],
    //     location: "Muscat, Sultanate of Oman",
    //     date: "28 Jan 2024",
    //     duration: "6 Days",
    // },
];

// Preload images function
const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
};

const Trainigprograms2023 = () => {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    // Preload images on component mount
    useEffect(() => {
        const allImages = projects.flatMap((project) => project.images || []);
        preloadImages(allImages);
    }, []);

    const handlePreviousImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    prevIndex === 0 ? projects[currentProjectIndex].images.length - 1 : prevIndex - 1
                );
                setIsFading(false);
            }, 250); // Match the fade-out timing
        }
    };

    const handleNextImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    (prevIndex + 1) % projects[currentProjectIndex].images.length
                );
                setIsFading(false);
            }, 250); // Adjust to half the CSS transition duration
        }
    };

    const handlePreviousProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex === 0 ? projects.length - 1 : prevIndex - 1));
        setMainImageIndex(0);
    };

    const handleNextProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
        setMainImageIndex(0);
    };

    const { images, title, description, location, sections, date, duration } =
        projects[currentProjectIndex];

    return (
        <section className="wpo-project-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-content">
                                {/* Image Slider */}
                                <div className="main-image-container">
                                    <button className="slider-arrow left-arrow" onClick={handlePreviousImage}>
                                        &larr;
                                    </button>
                                    <img
                                        src={images[mainImageIndex]}
                                        alt={`Project Slide ${mainImageIndex + 1}`}
                                        className={`main-slider-image ${isFading ? "fade-out" : "fade-in"}`}
                                    />
                                    <button className="slider-arrow right-arrow" onClick={handleNextImage}>
                                        &rarr;
                                    </button>
                                </div>

                                {/* Project Details */}
                                <div className="wpo-project-single-content-des">
                                    <div className="wpo-project-single-content-des-wrap">
                                        <div className="wpo-project-single-content-des-left">
                                            <h2>{title}</h2>

                                            {/* Render Description */}
                                            {description &&
                                                description.map((para, index) => (
                                                    <p key={index}>{para}</p>
                                                ))}

                                            {/* Render Sections with Headers and Their Bullet Points */}
                                            {sections &&
                                                sections.map((section, sectionIndex) => (
                                                    <div key={sectionIndex} style={{ marginBottom: "20px" }}>
                                                        {/* Render Section Header */}
                                                        {section.header && (
                                                            <h3 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>
                                                                {section.header}
                                                            </h3>
                                                        )}
                                                        {/* Render Section Bullet Points */}
                                                        {section.bulletPoints && (
                                                            <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
                                                                {section.bulletPoints.map((point, index) =>
                                                                    typeof point === "object" &&
                                                                    point.subPoints ? (
                                                                        <li key={index}>
                                                                            {point.mainPoint}
                                                                            <ul style={{ paddingLeft: "20px" }}>
                                                                                {point.subPoints.map(
                                                                                    (subPoint, subIndex) => (
                                                                                        <li key={subIndex}>
                                                                                            {subPoint}
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    ) : (
                                                                        <li key={index}>{point}</li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="wpo-project-single-content-des-right">
                                            <ul>
                                                <li>
                                                    Location: <span>{location}</span>
                                                </li>
                                                <li>
                                                    Date: <span>{date}</span>
                                                </li>
                                                <li>
                                                    Duration: <span>{duration}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Stylish Navigation Buttons */}
                            <div className="more-posts">
                                <button
                                    onClick={handlePreviousProject}
                                    className="arrow-btn prev-btn"
                                >
                                    &larr; Previous Project
                                </button>
                                <button
                                    onClick={handleNextProject}
                                    className="arrow-btn next-btn"
                                >
                                    Next Project &rarr;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style>{`
                .main-image-container {
                    position: relative;
                    width: 100%;
                    max-height: 500px;
                    overflow: hidden;
                    border-radius: 15px;
                }

                .main-slider-image {
                    width: 100%;
                    height: 500px;
                    object-fit: cover;
                    border-radius: 15px;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }

                .main-slider-image.fade-out {
                    opacity: 0;
                }

                .main-slider-image.fade-in {
                    opacity: 1;
                }

                .slider-arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 18px;
                    border-radius: 5px;
                }

                .slider-arrow.left-arrow {
                    left: 10px;
                }

                .slider-arrow.right-arrow {
                    right: 10px;
                }

                .slider-arrow:hover {
                    background: rgba(0, 0, 0, 0.8);
                }

                .arrow-btn {
                    background: linear-gradient(145deg, #003366, #004080); 
                    color: white;
                    border: none;
                    padding: 12px 24px;
                    border-radius: 25px;
                    font-size: 16px;
                    cursor: pointer;
                }

                .more-posts {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }
            `}</style>
        </section>
    );
};

export default Trainigprograms2023;
