import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styled components for layout
const Section = styled.section`
  padding: 100px 20px;
  background-color: #f4f7fc;
  font-family: 'Arial', sans-serif;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
`;

const SubTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
`;

const TextSection = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  text-align: left;
  color: #444;
  margin-bottom: 50px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.7;
  margin-bottom: 20px;
  color: #555;
`;

const SectionTitle = styled.h3`
  font-size: 2rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 30px;
  text-transform: capitalize;
  letter-spacing: 1px;
`;

const MissionVisionGoalsSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px 0;
  gap: 40px;
`;

const MissionVisionGoalsCard = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 320px;
  transition: transform 0.3s ease-in-out;
  text-align: left;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  }
`;

const CardTitle = styled.h3`
  font-size: 2.2rem;
  font-weight: 600;
  color: #003366;
  margin-bottom: 20px;
  text-transform: capitalize;
`;

const CardDescription = styled.div`
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;

  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 15px;
  }

  li {
    margin-bottom: 12px;
  }
`;

const CallToAction = styled.div`
  background-color: #e9ecf5;
  color: #003366;
  padding: 80px 20px;
  text-align: center;
  margin-top: 60px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const CallToActionTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 20px;
`;

const CallToActionText = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;
`;

const Button = styled(Link)`
  display: inline-block;
  background-color: #003366;
  color: #fff;
  padding: 15px 30px;
  text-decoration: none;
  font-size: 1.2rem;
  border-radius: 6px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #002244;
  }
`;

// New Section styles
const TeamSection = styled.div`
  margin-top: 80px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  }
`;

const TeamTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const TeamDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555;
`;

const TrainersSection = styled.div`
  margin-top: 60px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const TrainersTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const TrainerDescription = styled.div`
  font-size: 1.2rem;
  color: #666;
  line-height: 1.8;

  ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  li {
    margin-bottom: 12px;
  }
`;

const TrainingMaterialSection = styled.div`
  margin-top: 60px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const TrainingMaterialTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const PracticalCasesSection = styled.div`
  margin-top: 60px;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
`;

const PracticalCasesTitle = styled.h3`
  font-size: 2.4rem;
  font-weight: 700;
  color: #003366;
  margin-bottom: 30px;
`;

const AboutPage = () => {
  return (
    <Section>
      <Container>
        <SubTitle>Your Trusted Partner for Institutional Excellence</SubTitle>

        {/* Original Mission, Vision, and Goals Section */}
        <MissionVisionGoalsSection>
          <MissionVisionGoalsCard>
            <CardTitle>Mission</CardTitle>
            <CardDescription>
              Our mission is to elevate training standards, competencies, and curricula to exceed industry norms across all professions. We are committed to delivering superior consulting, training, and technical administrative development services. Our aim is to enhance service quality, help achieve organizational goals, and prepare clients to meet evolving market demands and future challenges with a dedicated and exceptional team.
            </CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>Vision</CardTitle>
            <CardDescription>
              Our vision is to become a premier institution recognized for excellence in fostering institutional development and enhancing performance across diverse sectors. We aim to be the preferred choice for organizations seeking comprehensive capacity building and the achievement of exceptional quality standards.
            </CardDescription>
          </MissionVisionGoalsCard>
          <MissionVisionGoalsCard>
            <CardTitle>Goals</CardTitle>
            <CardDescription>
              <ul>
                <li>To extend training and advisory services to encompass all sectors across the Arab world.</li>
                <li>Strive to increase the performance and success rates of both public and private sector organizations.</li>
                <li>To develop local and international collaborations to elevate workforce competencies and broaden professional networks.</li>
              </ul>
            </CardDescription>
          </MissionVisionGoalsCard>
        </MissionVisionGoalsSection>

        {/* New Section: Our Team at Work */}
        <TeamSection>
          <TeamTitle>Our Team at Work</TeamTitle>
          <TeamDescription>
            Mafaz is supported by a dedicated team of administrators, experts, and consultants, all with high academic qualifications, exceptional expertise, multilingual abilities, and outstanding skills. This team plays a vital role in developing our clients' human capital, positively impacting communities. Team selection is based on Best International Practices.
          </TeamDescription>
        </TeamSection>

        <TrainersSection>
    <TrainersTitle>Mafaz Trainers and Experts</TrainersTitle>
    <TrainerDescription>
        Mafaz’s trainers are the foundation of its success and growth. Each trainer is carefully chosen based on their specialization and must meet rigorous criteria to conduct training programs effectively:
        <ul className="trainer-list">
            <li>Academic Qualifications: High-level academic background relevant to their area of expertise.</li>
            <li>Practical Experience: Significant experience, including leadership roles in relevant fields.</li>
            <li>Professional Qualifications: Certifications from specialized international institutions.</li>
            <li>Personal Interview: Evaluation through one-on-one discussions.</li>
            <li>Professional Memberships: Affiliations with recognized international and Arab training and consulting institutions.</li>
            <li>Alignment of Expertise: Trainers’ expertise aligns closely with the areas they teach and advise on.</li>
            <li>Core Trainer Skills: Skills in communication, presentation, modern training tools, and participant engagement.</li>
        </ul>
    </TrainerDescription>
    <style>{`
        .trainer-list {
            padding: 0; /* Remove default padding */
            margin: 0; /* Remove default margin */
            list-style-position: outside; /* Align bullets flush left */
            text-align: left; /* Align text to the left */
        }
        .trainer-list li {
            padding: 0; /* Remove extra padding inside list items */
            margin: 0 0 10px 0; /* Add spacing between bullet points */
        }
    `}</style>
</TrainersSection>






        {/* Training Material */}
        <TrainingMaterialSection>
          <TrainingMaterialTitle>Training Material</TrainingMaterialTitle>
          <TeamDescription>
            To ensure high quality, training materials undergo a thorough approval process by the Mafaz Quality Review Committee, which consists of accredited experts. The committee assesses materials for adherence to quality standards and relevance to specified training topics.
          </TeamDescription>
        </TrainingMaterialSection>
        

        {/* Call to Action */}
        <CallToAction>
          <CallToActionTitle>Ready to take your organization to the next level?</CallToActionTitle>
          <CallToActionText>Contact us today to learn more about our consulting and training solutions.</CallToActionText>
          <Button to="/contact">Get in Touch</Button>
        </CallToAction>
      </Container>
    </Section>
  );
};

export default AboutPage;
