import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Trainigprograms2022 from '../../components/Trainigprograms2022';

import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const TrainingPrograms2022 =() => {
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'Training Programs 2022'} pagesub={'Services'}/> 
            <Trainigprograms2022/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPrograms2022;