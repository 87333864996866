import React, { useState, useEffect } from 'react';

// Import images

//The Integrated System Program for Building and Managing High-Performance Teams
import prjImg1 from '../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image1.webp';
import prjImg2 from '../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image2.webp';
import prjImg20 from '../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image3.webp';
import prjImg29 from '../../images/training_programs_2024/The Integrated System Program for Building and Managing High-Performance Teams/image4.webp';

//Modern Technologies in Internal Auditing
import prjImg3 from '../../images/training_programs_2024/Modern Technologies in Internal Auditing/image1.webp';
import prjImg9 from '../../images/training_programs_2024/Modern Technologies in Internal Auditing/image2.webp';
import prjImg10 from '../../images/training_programs_2024/Modern Technologies in Internal Auditing/image3.webp';
import prjImg11 from '../../images/training_programs_2024/Modern Technologies in Internal Auditing/image4.webp';

//Professional Leadership and Facing Challenges
import prjImg4 from '../../images/training_programs_2024/Professional Leadership and Facing Challenges/image1.webp';
import prjImg12 from '../../images/training_programs_2024/Professional Leadership and Facing Challenges/image2.webp';
import prjImg13 from '../../images/training_programs_2024/Professional Leadership and Facing Challenges/image3.webp';
import prjImg14 from '../../images/training_programs_2024/Professional Leadership and Facing Challenges/image4.webp';

//Emergency Planning and Crisis Management
import prjImg5 from '../../images/training_programs_2024/Emergency Planning and Crisis Management/image1.webp';
import prjImg15 from '../../images/training_programs_2024/Emergency Planning and Crisis Management/image2.webp';
import prjImg16 from '../../images/training_programs_2024/Emergency Planning and Crisis Management/image3.webp';

//Utilizing Artificial Intelligence in the Workplace
import prjImg6 from '../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image1.webp';
import prjImg7 from '../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image2.webp';
import prjImg17 from '../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image3.webp';
import prjImg18 from '../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image4.webp';
import prjImg19 from '../../images/training_programs_2024/Utilizing Artificial Intelligence in the Workplace/image5.webp';

//Technical Inspection (VAT, Excise Tax, and Refund)
import prjImg21 from '../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image1.webp';
import prjImg22 from '../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image2.webp';
import prjImg23 from '../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image3.webp';
import prjImg24 from '../../images/training_programs_2024/Technical Inspection (VAT, Excise Tax, and Refund)/image4.webp';

//Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax
import prjImg25 from '../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image1.webp';
import prjImg26 from '../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image2.webp';
import prjImg27 from '../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image3.webp';
import prjImg28 from '../../images/training_programs_2024/Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax/image4.webp';

import prjImg30 from '../../images/training_programs_2024/Risk and Disaster Management/image1.jpg';
import prjImg31 from '../../images/training_programs_2024/Risk and Disaster Management/image2.jpg';

// Project data array
const projects = [


    {
        images: [prjImg30,prjImg31],
        title: "Risk and Disaster Management",
        description: [
            "Participants gained a deep understanding of the importance of proactive planning and coordination among various stakeholders, as well as learned about global best practices and methods in crisis management. This ensured the ability to handle various scenarios—from natural disasters to industrial incidents—in a way that minimized negative impacts and protected lives and property.",
            "This course was not only an opportunity for individual skill development but also directly contributed to strengthening institutional capabilities. It enhanced disaster preparedness and readiness, enabling organizations to perform their duties more effectively. This, in turn, solidified their reputation as reliable entities capable of protecting the community and ensuring its safety at all times. ",
            "At the end of the program, participants engaged in a comprehensive project focused on planning and implementing an integrated response to multidimensional emergencies.",
        ],
        location: "Amman, Jordan",
        date: "24 Nov 2024",
        duration: "5 Days",
    },
    
    



    
    {
        images: [prjImg1, prjImg2,prjImg20,prjImg29],
        title: "The Integrated System Program for Building and Managing High-Performance Teams",
        description: [
            "This program was conducted to equip participants with the knowledge, skills, and behaviors that enhance the efficiency and effectiveness of committees and work teams to achieve the institution's goals and the desired excellence and leadership. This aligns with modern management trends to achieve optimal performance and sustain outstanding results. A select group of managers from an Omani ministry participated in the program.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "7 Oct 2024",
        duration: "3 Days",
    },
    {
        images: [prjImg3,prjImg9,prjImg10,prjImg11],
        title: "Modern Technologies in Internal Auditing",
        description: [
            "This training program aims to enhance internal auditors' understanding across various sectors of the core concepts of internal auditing and to develop their skills in using modern technologies in audit practices. It focuses on improving the accuracy and integrity of audit results, expanding risk coverage, and enabling participants to identify emerging risks more quickly. The program will also cover the latest technologies used in internal auditing, such as digital auditing, data analysis, machine learning, artificial intelligence, continuous auditing, Computer-Assisted Audit Tools (CAATs), risk-based auditing, and the application of artificial intelligence techniques in various auditing fields.",
        ],
        location: "Salalah, Sultanate of Oman",
        date: "4 Aug 2024",
        duration: "5 Days",
    },
    {
        images: [prjImg4,prjImg12,prjImg13,prjImg14],
        title: "Professional Leadership and Facing Challenges",
        description: [
            "Given the need for administrative and leadership position holders to be equipped with advanced leadership skills that allow them to lead their institutions, departments, and divisions with utmost professionalism, especially amid global competition, the dominance of globalization, and emerging challenges, this program aims to help achieve exceptional institutions where creativity is the approach and excellence is a hallmark throughout the organization and across all employees at various levels. Believing that the success of institutions stems from the success of their leaders, this program is directed at leaders to help them achieve the desired goals.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "3 Mar 2024",
        duration: "3 Days",
    },
    {
        images: [prjImg5,prjImg15,prjImg16],
        title: "Emergency Planning and Crisis Management",
        description: [
            "The Emergency Planning and Crisis Management course provides essential tools and knowledge to help employees in critical sectors act effectively and swiftly during crises, thereby reducing damage and ensuring the safety of facilities and individuals. Participants will learn how to assess risks, develop comprehensive response strategies, and coordinate efforts across different teams to ensure service continuity and protect the community. Through practical training and specialized theoretical content, participants will gain an understanding of the importance of emergency planning and its effective application within their institutions. ",
            "The benefits of this course go beyond enhancing participants' crisis management capabilities; it also fosters an institutional culture rooted in preparedness and planning, enabling participants to transfer acquired knowledge and skills to their teams. This strengthens the overall readiness of the institution and ensures the continuity of vital services even in the most challenging circumstances. In summary, this course is an investment in safety and institutional stability, positively impacting the community as a whole.",
        ],
        location: "Muscat, Sultanate of Oman",
        date: "28 Jul 2024",
        duration: "4 Days",
    },
    {
        images: [prjImg6, prjImg7,prjImg17,prjImg18,prjImg19],
        title: "Utilizing Artificial Intelligence in the Workplace",
        description: [
            "This program serves as an awareness initiative on integrating artificial intelligence in the workplace, attended by a group of employees and managers from various administrative levels of a governmental entity. The program aims to help participants differentiate between all types of artificial intelligence, understand its various applications, identify the fields where AI can operate, recognize AI applications upon seeing them, analyze their working mechanisms, and select future study areas in AI."
        ],
        sections: [
            {
                header: "The program covered several topics, including:",
                bulletPoints: [
                    "The benefits and risks associated with using artificial intelligence in organizations and governmental institutions.",
                    "The Global Information Preservation System.",
                    "Ethics in dealing with artificial intelligence.",
                    "Correlation versus causation.",
                    "The random sampling system to establish necessary standards.",
                    "Web data analysis and mediator models.",
                ],
            },
        ],
        location: "Muscat, Sultanate of Oman",
        date: "8 Sep 2024",
        duration: "1 Day",
    },
    {
        images: [prjImg21, prjImg22, prjImg23,prjImg24],
        title: "Technical Inspection (VAT, Excise Tax, and Refund)",
        description: [
            "This program aims to introduce participants to contemporary tax systems, their types, and the principles and rules for imposing various types of taxes. It seeks to develop participants' accounting skills in the field of tax accounting and tax settlement, including methods for determining tax bases, deductible costs for each type, and distinguishing between tax exemptions and non-taxable status.",
            "The program also aims to equip participants with skills in conducting tax audits of files, accounts, and taxable activities, enhancing their abilities in applying VAT, and guiding them on registration for VAT purposes. Additionally, it aims to develop participants' skills in reviewing periodic tax reports and declarations in light of tax regulations and laws according to the requirements of the tax administration responsible for tax collection.",
            "Through the program, participants are introduced to the concept of tax evasion, its motivations, and methods, along with anti-evasion strategies. It also seeks to promote tax awareness and culture among accountants and those interested in VAT matters, reinforcing the principle of compliance with tax legislation and fostering cooperation and partnership with various sectors.",

        ],
        location: "Muscat, Sultanate of Oman",
        date: "8 Sep 2024",
        duration: "5 Days",
    },
    {
        images: [prjImg25, prjImg26, prjImg27,prjImg28],
        title: "Tax Evasion Techniques in Value-Added Tax (VAT) and Excise Tax",
        description: [
            "The program targeted a group of auditors working in the tax sector to equip them with skills in calculating VAT, examining and auditing tax returns, and detecting tax evasion and avoidance. It covered key tax concepts related to VAT and excise tax, as well as types of direct and indirect taxes and the tax systems of various countries. ",
            "The program addressed topics such as tax returns, procedures for submission, amendment, and assessment; the importance of tax auditing, the scope of audit operations, steps for preparing tax audits, and standards for decision approval; violations and penalties; calculation of VAT on external purchases and its relation to tax evasion; reasons and motives behind tax evasion; methods and techniques of tax evasion and strategies to combat it; requirements for tax audit and examination; and the significance of the Anti-Tax Evasion Unit and its role in gathering information and investigation.",

        ],
        location: "Muscat, Sultanate of Oman",
        date: "28 Jan 2024",
        duration: "5 Days",
    },
];

// Function to preload images
const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
};

const Trainigprograms2024 = () => {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    // Preload all project images on mount
    useEffect(() => {
        const allImages = projects.flatMap((project) => project.images || []);
        preloadImages(allImages);
    }, []);

    const handlePreviousImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    prevIndex === 0 ? projects[currentProjectIndex].images.length - 1 : prevIndex - 1
                );
                setIsFading(false);
            }, 250); // Match the fade-out timing
        }
    };

    const handleNextImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    (prevIndex + 1) % projects[currentProjectIndex].images.length
                );
                setIsFading(false);
            }, 250); // Adjust to half the CSS transition duration
        }
    };

    const handlePreviousProject = () => {
        setCurrentProjectIndex((prevIndex) =>
            prevIndex === 0 ? projects.length - 1 : prevIndex - 1
        );
        setMainImageIndex(0);
    };

    const handleNextProject = () => {
        setCurrentProjectIndex((prevIndex) =>
            (prevIndex + 1) % projects.length
        );
        setMainImageIndex(0);
    };

    const { images, title, description, location, sections, date, duration } =
        projects[currentProjectIndex];

    return (
        <section className="wpo-project-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-content">
                                {/* Image Slider */}
                                <div className="main-image-container">
                                    <button className="slider-arrow left-arrow" onClick={handlePreviousImage}>
                                        &larr;
                                    </button>
                                    <img
                                        src={images[mainImageIndex]}
                                        alt={`Project Slide ${mainImageIndex + 1}`}
                                        className={`main-slider-image ${isFading ? "fade-out" : "fade-in"}`}
                                    />
                                    <button className="slider-arrow right-arrow" onClick={handleNextImage}>
                                        &rarr;
                                    </button>
                                </div>

                                {/* Project Details */}
                                <div className="wpo-project-single-content-des">
                                    <div className="wpo-project-single-content-des-wrap">
                                        <div className="wpo-project-single-content-des-left">
                                            <h2>{title}</h2>

                                            {/* Render Description */}
                                            {description &&
                                                description.map((para, index) => (
                                                    <p key={index}>{para}</p>
                                                ))}

                                            {/* Render Sections with Headers and Their Bullet Points */}
                                            {sections &&
                                                sections.map((section, sectionIndex) => (
                                                    <div key={sectionIndex} style={{ marginBottom: "20px" }}>
                                                        {/* Render Section Header */}
                                                        {section.header && (
                                                            <h3 style={{ marginBottom: "10px", fontSize: "1.2rem" }}>
                                                                {section.header}
                                                            </h3>
                                                        )}
                                                        {/* Render Section Bullet Points */}
                                                        {section.bulletPoints && (
                                                            <ul style={{ paddingLeft: "20px", listStyleType: "disc" }}>
                                                                {section.bulletPoints.map((point, index) =>
                                                                    typeof point === "object" &&
                                                                    point.subPoints ? (
                                                                        <li key={index}>
                                                                            {point.mainPoint}
                                                                            <ul style={{ paddingLeft: "20px" }}>
                                                                                {point.subPoints.map(
                                                                                    (subPoint, subIndex) => (
                                                                                        <li key={subIndex}>
                                                                                            {subPoint}
                                                                                        </li>
                                                                                    )
                                                                                )}
                                                                            </ul>
                                                                        </li>
                                                                    ) : (
                                                                        <li key={index}>{point}</li>
                                                                    )
                                                                )}
                                                            </ul>
                                                        )}
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="wpo-project-single-content-des-right">
                                            <ul>
                                                <li>
                                                    Location: <span>{location}</span>
                                                </li>
                                                <li>
                                                    Date: <span>{date}</span>
                                                </li>
                                                <li>
                                                    Duration: <span>{duration}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Stylish Navigation Buttons */}
                            <div className="more-posts">
                                <button
                                    onClick={handlePreviousProject}
                                    className="arrow-btn prev-btn"
                                >
                                    &larr; Previous Project
                                </button>
                                <button
                                    onClick={handleNextProject}
                                    className="arrow-btn next-btn"
                                >
                                    Next Project &rarr;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style>{`
                .main-image-container {
                    position: relative;
                    width: 100%;
                    max-height: 500px;
                    overflow: hidden;
                    border-radius: 15px;
                }

                .main-slider-image {
                    width: 100%;
                    height: 500px;
                    object-fit: cover;
                    border-radius: 15px;
                    opacity: 1;
                    transition: opacity 0.5s ease-in-out;
                }

                .main-slider-image.fade-out {
                    opacity: 0;
                }

                .main-slider-image.fade-in {
                    opacity: 1;
                }

                .slider-arrow {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.5);
                    color: white;
                    border: none;
                    padding: 10px 20px;
                    cursor: pointer;
                    font-size: 18px;
                    border-radius: 5px;
                }

                .slider-arrow.left-arrow {
                    left: 10px;
                }

                .slider-arrow.right-arrow {
                    right: 10px;
                }

                .slider-arrow:hover {
                    background: rgba(0, 0, 0, 0.8);
                }

                .arrow-btn {
                    background: linear-gradient(145deg, #003366, #004080); 
                    color: white;
                    border: none;
                    padding: 12px 24px;
                    border-radius: 25px;
                    font-size: 16px;
                    cursor: pointer;
                }

                .more-posts {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 30px;
                }
            `}</style>
        </section>
    );
};

export default Trainigprograms2024;
