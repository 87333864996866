import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import prjImg1 from '../../images/clients/bankdhofar.webp';
import prjImg2 from '../../images/clients/civildefense.webp';
import prjImg3 from '../../images/clients/commerce.webp';
import prjImg4 from '../../images/clients/defenseministry.webp';
import prjImg5 from '../../images/clients/education.webp';
import prjImg6 from '../../images/clients/foreign.webp';
import prjImg7 from '../../images/clients/housingandurban.webp';
import prjImg8 from '../../images/clients/madayn.webp';
import prjImg9 from '../../images/clients/media.webp';
import prjImg10 from '../../images/clients/msb.webp';
import prjImg11 from '../../images/clients/msx.webp';
import prjImg12 from '../../images/clients/nama.webp';
import prjImg13 from '../../images/clients/nbo.webp';
import prjImg14 from '../../images/clients/omanguard.webp';
import prjImg15 from '../../images/clients/omanoil.webp';
import prjImg16 from '../../images/clients/omanpolice.webp';
import prjImg17 from '../../images/clients/royalcourt.webp';
import prjImg18 from '../../images/clients/royalopera.webp';
import prjImg19 from '../../images/clients/scientific.webp';
import prjImg20 from '../../images/clients/socialprotectionfund.webp';
import prjImg21 from '../../images/clients/tax.webp';
import prjImg22 from '../../images/clients/tradingandinvestment.webp';
import prjImg23 from '../../images/clients/youth.webp';
import "./AllClients.css"


const AllClients = () => {
    const projects = [
        { pImg: prjImg1, title: 'Dhofar Bank' },
        { pImg: prjImg3, title: 'Ministry of Commerce, Industry & Investment Promotion' },
        { pImg: prjImg4, title: 'Ministry of Defence' },
        { pImg: prjImg5, title: 'Ministry of Education' },
        { pImg: prjImg6, title: 'Ministry of Foreign Affairs' },
        { pImg: prjImg7, title: 'Ministry of Housing and Urban Planning' },
        { pImg: prjImg8, title: 'Madayn' },
        { pImg: prjImg11, title: 'MSX' },
        { pImg: prjImg12, title: 'Nama Water Services' },
        { pImg: prjImg13, title: 'National Bank of Oman' },
        { pImg: prjImg14, title: 'Royal Guard of Oman' },
        { pImg: prjImg15, title: 'Oman Oil' },
        { pImg: prjImg17, title: 'Royal Court of Oman' },
        { pImg: prjImg19, title: 'Ministry of Higher Education, Scientific Research and Innovation' },
        { pImg: prjImg21, title: 'Tax Authority' },
        { pImg: prjImg22, title: 'Trading & Investment Establishment' },
        { pImg: prjImg23, title: 'Ministry of Culture, Sports and Youth' },
        { pImg: prjImg20, title: 'Social Protection Fund' },
        { pImg: prjImg10, title: 'MSB' },
        { pImg: prjImg9, title: 'Ministry of Media' },
        { pImg: prjImg16, title: 'Oman Royal Police' },
        { pImg: prjImg18, title: 'Royal Opera House Muscat' },
        { pImg: prjImg2, title: 'Civil Defense and Ambulance Authority' },



        

    ];

    const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        swipeToSlide: true, // Allows swiping to exact position
        draggable: true, // Enables natural dragging
        touchThreshold: 15, // Adjust sensitivity of dragging
        centerMode: false, // Disable slide centering
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    function CustomArrow({ direction, onClick }) {
        return (
            <button
                className={`custom-arrow custom-arrow-${direction}`}
                onClick={onClick}
            >
                {direction === "next" ? ">" : "<"}
            </button>
        );
    }

    return (
        <section className="all-clients-section">
            <div className="container">
                <div className="section-title">
                    <h2>
                        Our <span>Clients</span>
                    </h2>
                </div>
                <Slider {...sliderSettings}>
                    {projects.map((project, index) => (
                        <div key={index} className="slider-item">
                            <img
                                src={project.pImg}
                                alt={project.title}
                                className="slider-image"
                            />
                            <h3 className="slider-title">{project.title}</h3>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default AllClients;
