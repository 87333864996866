import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero2 from '../../components/hero2'
import Features from '../../components/Features'

import Testimonial from '../../components/Testimonial'
import Support from '../../components/Support'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import WhoWeAre from '../../components/Whoarewe'
import UpcomingTraining from '../../components/trainingplan'
import MediaGallery from '../../components/MediaPage';
const HomePage2 =() => {
    return(
        <Fragment>
            <Navbar/>
            <Hero2/>
            <WhoWeAre/>
            {/* <Features/> */}
            <UpcomingTraining/>
            {/* <MediaGallery/> */}
            {/* <About/> */}
            {/* <Testimonial/> */}
            <Support/>
            {/* <FunFact fnClass={'section-padding'}/> */}
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage2;