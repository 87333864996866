import React from "react";
import trainingCover from "../../images/training.webp"; // Training plan cover image
import trainingPlanPDF from "../../files/Mafaz_Training_plan_2024.pdf"; // PDF file to download
import "./UpcomingTraining.css"; // Import the CSS file

const UpcomingTraining = () => {
    const programs = [
        { title: "Preparing Audit Manuals", location: "Muscat-Oman", date: "3-7/11" },
        { title: "Skills of Preparing and Presenting Financial Reports in Banks", location: "Istanbul-Turkey", date: "3-7/11" },
        { title: "Auditing Projects, Contracts and Tenders", location: "Muscat-Oman", date: "3-7/11" },
        { title: "Leadership in Light of Crises and Risks", location: "Casablanca-Morocco", date: "10-14/11" },
        { title: "E-marketing and Its Role in Increasing the Competitiveness of the Organization", location: "Amman-Jordan", date: "10-14/11" },

        
        // Add more programs here...
    ];

    return (
        <section className="training-section">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-5">
                        <div className="training-cover">
                            <a href={trainingPlanPDF} download="Mafaz_Training_plan_2024">
                                <img src={trainingCover} alt="Training Plan 2024" />
                                <h2>GET THE TRAINING PLAN <span>2024</span></h2>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="training-programs">
                            <h3>Upcoming Programs</h3>
                            <ul className="program-list">
                                {programs.map((program, index) => (
                                    <li key={index} className="program-item">
                                        <span className="program-title">{program.title}</span>
                                        <span className="program-location">{program.location}</span>
                                        <span className="program-date">{program.date}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UpcomingTraining;
