import React, { useState, useEffect, useCallback, memo } from 'react';


// Import images for both forums
import prjImg1 from '../../images/forums/The annual internal Audit forum/image1.webp';
import prjImg2 from '../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image1.webp';
import prjImg3 from '../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image2.webp';
import prjImg4 from '../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image3.webp';
import prjImg10 from '../../images/forums/Annual Forum Creative Leadership in the Context of Good Governance and Institutional Excellence/image4.jpg';

import prjImg5 from '../../images/forums/The annual internal Audit forum/image2.webp';
import prjImg6 from '../../images/forums/The annual internal Audit forum/image3.webp';
import prjImg7 from '../../images/forums/The annual internal Audit forum/image4.webp';
import prjImg8 from '../../images/forums/The annual internal Audit forum/image5.webp';
import prjImg9 from '../../images/forums/The annual internal Audit forum/image6.webp';

// Project data for forums
const projects = [
    {
        images: [prjImg1,prjImg5,prjImg6,prjImg7,prjImg8,prjImg9],
        title: "The Annual Internal Audit Forum: International Internal Auditing Standards issued by the Institute of Internal Auditors (IIA)",
        description: [
            "In light of the rapid developments in the business and technology sectors, as well as the crises and disasters that have impacted the world in recent years—greatly affecting various professions, including internal auditing—the Institute of Internal Auditors (IIA) has restructured the internal audit standards to keep pace with these changes and developments."
        ],
        bulletPointsHeader: "This forum was organized to address three critical papers relevant to the field of internal auditing:",
        bulletPoints: [
            "The 2024 Global Internal Audit Standards and the recommended new amendments to be implemented starting in 2025.",
            "Remote auditing and the role of artificial intelligence in enhancing the quality of the auditing process in the context of information technology and Blockchain applications.",
            "Internal auditing and its role in meeting cybersecurity requirements.",
        ],
        additionalInfo: [
            "This forum was attended by a range of internal and external audit managers, heads of internal audit committees, internal and external auditors, as well as internal and external quality assessors and performance evaluators from various ministries and government agencies. The event was led by a group of international experts in internal auditing."
        ],
        location: "Muscat, Sultanate of Oman",
        date: "29 Apr 2024",
        duration: "3 Days",
    },
    {
        images: [prjImg2, prjImg3, prjImg4,prjImg10],
        title: "Annual Forum: Creative Leadership in the Context of Good Governance and Institutional Excellence",
        tagline: "Towards Leaders Who Create Change and Embrace Challenges",
        description: [
            "This forum was initiated because we believe that Oman’s Vision 2040 represents a transformative leap and a significant challenge in today's globally competitive world. Recognizing that the main driver is the individual equipped with knowledge, skills, and strong willpower, this forum aims to highlight creative leadership, encourage thinking beyond the conventional, and move away from standardized administrative solutions. It seeks to awaken and optimally harness individuals’ latent potential, foster motivation and passion within the work environment, shift from individualism to team spirit, overcome challenges, and draw on successful global experiences in this regard, ultimately achieving the noble goal envisioned by Oman’s Vision 2040."
        ],
        bulletPointsHeader: "",
        bulletPoints: [], // Ensure this is at least an empty array
        additionalInfo: [], // Ensure this is at least an empty array
        location: "Muscat, Sultanate of Oman",
        date: "23 Oct 2023",
        duration: "3 Days",
    }
];

const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
      const img = new Image();
      img.src = url;
    });
  };
  
  const ForumsPage = () => {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);
  
    const currentProject = projects[currentProjectIndex];
  
    // Preload all images when the component mounts
    useEffect(() => {
      const allImages = projects.flatMap((project) => project.images || []);
      preloadImages(allImages);
    }, []);
  
    // Handle next and previous image with fade transition
    const handlePreviousImage = useCallback(() => {
      if (!isFading) {
        setIsFading(true);
        setTimeout(() => {
          setMainImageIndex((prevIndex) =>
            prevIndex === 0 ? currentProject.images.length - 1 : prevIndex - 1
          );
          setIsFading(false);
        }, 250); // Match transition duration
      }
    }, [isFading, currentProject.images.length]);
  
    const handleNextImage = useCallback(() => {
      if (!isFading) {
        setIsFading(true);
        setTimeout(() => {
          setMainImageIndex((prevIndex) =>
            (prevIndex + 1) % currentProject.images.length
          );
          setIsFading(false);
        }, 250); // Match transition duration
      }
    }, [isFading, currentProject.images.length]);
  
    // Handle next and previous project
    const handlePreviousProject = () => {
      setCurrentProjectIndex((prevIndex) =>
        prevIndex === 0 ? projects.length - 1 : prevIndex - 1
      );
      setMainImageIndex(0); // Reset to first image
    };
  
    const handleNextProject = () => {
      setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
      setMainImageIndex(0); // Reset to first image
    };
  
    return (
      <section className="wpo-project-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <div className="wpo-project-single-wrap">
                <div className="wpo-project-single-content">
                  {/* Image Slider */}
                  <div className="main-image-container">
                    {currentProject.images.length > 0 ? (
                      <>
                        <button className="slider-arrow left-arrow" onClick={handlePreviousImage}>
                          &larr;
                        </button>
                        <img
                          src={currentProject.images[mainImageIndex]}
                          alt={`Slide ${mainImageIndex + 1}`}
                          className={`main-slider-image ${isFading ? 'fade-out' : 'fade-in'}`}
                        />
                        <button className="slider-arrow right-arrow" onClick={handleNextImage}>
                          &rarr;
                        </button>
                      </>
                    ) : (
                      <p style={{ textAlign: 'center', padding: '20px', color: '#555' }}>No images available.</p>
                    )}
                  </div>
  
                  {/* Project Details */}
                  <div className="wpo-project-single-content-des">
                    <div className="wpo-project-single-content-des-wrap">
                      <div className="wpo-project-single-content-des-left">
                        <h2>{currentProject.title}</h2>
                        {currentProject.tagline && (
                          <p style={{ fontStyle: 'italic', marginBottom: '20px' }}>
                            {currentProject.tagline}
                          </p>
                        )}
                        {currentProject.description.map((para, index) => (
                          <p key={index}>{para}</p>
                        ))}
                        {currentProject.bulletPointsHeader && (
                          <h3 style={{ marginBottom: '10px', fontSize: '1.2rem', fontWeight: 'bold' }}>
                            {currentProject.bulletPointsHeader}
                          </h3>
                        )}
                        {currentProject.bulletPoints && (
                          <ul
                            style={{
                              paddingLeft: '20px',
                              listStyleType: 'disc',
                              color: '#333',
                              fontSize: '1rem',
                            }}
                          >
                            {currentProject.bulletPoints.map((point, index) => (
                              <li key={index} style={{ marginBottom: '8px', lineHeight: '1.6' }}>
                                {point}
                              </li>
                            ))}
                          </ul>
                        )}
                        {currentProject.additionalInfo.map((info, index) => (
                          <p key={index}>{info}</p>
                        ))}
                      </div>
  
                      <div className="wpo-project-single-content-des-right">
                        <ul>
                          <li>Location: <span>{currentProject.location}</span></li>
                          <li>Date: <span>{currentProject.date}</span></li>
                          <li>Duration: <span>{currentProject.duration}</span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
  
                {/* Navigation Buttons */}
                <div className="more-posts">
                  <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                    &larr; Previous Forum
                  </button>
                  <button onClick={handleNextProject} className="arrow-btn next-btn">
                    Next Forum &rarr;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Styles */}
        <style>{`
          .main-image-container {
            position: relative;
            width: 100%;
            max-height: 500px;
            overflow: hidden;
            border-radius: 15px;
          }
          .main-slider-image {
            width: 100%;
            height: 500px;
            object-fit: cover;
            border-radius: 15px;
            opacity: 1;
            transition: opacity 0.25s ease-in-out;
          }
          .main-slider-image.fade-out {
            opacity: 0;
          }
          .main-slider-image.fade-in {
            opacity: 1;
          }
          .slider-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            background: rgba(0, 0, 0, 0.5);
            color: white;
            border: none;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 18px;
            border-radius: 5px;
          }
          .slider-arrow.left-arrow {
            left: 10px;
          }
          .slider-arrow.right-arrow {
            right: 10px;
          }
          .slider-arrow:hover {
            background: rgba(0, 0, 0, 0.8);
          }
          .arrow-btn {
            background: linear-gradient(145deg, #003366, #004080);
            color: white;
            border: none;
            padding: 12px 24px;
            border-radius: 25px;
            font-size: 16px;
            cursor: pointer;
          }
          .more-posts {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
          }
          .wpo-project-single-content-des-wrap {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          }
          .wpo-project-single-content-des-left {
            flex: 0 0 70%;
            padding-right: 20px;
          }
          .wpo-project-single-content-des-right {
            flex: 0 0 30%;
          }
          .wpo-project-single-content-des-right ul {
            list-style: none;
            padding: 0;
          }
          .wpo-project-single-content-des-right li {
            margin-bottom: 10px;
            font-size: 1rem;
            color: #555;
          }
          .wpo-project-single-content-des-right span {
            font-weight: bold;
          }
        `}</style>
      </section>
    );
  };
  
  export default memo(ForumsPage);