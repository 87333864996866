import React, { useState, useEffect } from 'react';

// Import images

//training_programs_2022/Protection Plan Procedures for Critical Facilities
import prjImg1 from '../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image1.webp';
import prjImg2 from '../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image2.webp';
import prjImg3 from '../../images/training_programs_2022/Protection Plan Procedures for Critical Facilities/image3.webp';

//Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures
import prjImg4 from '../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image1.webp';
import prjImg5 from '../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image2.webp';
import prjImg6 from '../../images/training_programs_2022/Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures/image3.webp';

//training_programs_2022/Finance for non Finance Manager
import prjImg7 from '../../images/training_programs_2022/Finance for non Finance Manager/image1.webp';
import prjImg8 from '../../images/training_programs_2022/Finance for non Finance Manager/image2.webp';
import prjImg9 from '../../images/training_programs_2022/Finance for non Finance Manager/image3.webp';

//Financial Accounting Skills
import prjImg10 from '../../images/training_programs_2022/Financial Accounting Skills/image1.webp';
import prjImg11 from '../../images/training_programs_2022/Financial Accounting Skills/image2.webp';
import prjImg12 from '../../images/training_programs_2022/Financial Accounting Skills/image3.webp';

// Project data array
const projects = [
    {
        images: [prjImg1, prjImg2, prjImg3],
        title: "Protection Plan Procedures for Critical Facilities",
        description: [
            "The program aims to identify the criteria that classify a facility as critical or strategic. It also presents the risks and challenges facing critical facilities and covers methods for threat mitigation, risk assessment, management, and inspection. In this context, the program discusses collaboration and coordination with local authorities and civil-military coordination (CIMC) for facility protection.",
        ],
        location: "Amman, Jordan",
        date: "25 Sep 2022",
        duration: "5 Days",
    },
    {
        images: [prjImg6, prjImg5, prjImg4],
        title: "Fundamentals of Off-Road Driving and Handling Emergency Mechanical Failures",
        description: [
            "This specialized program, designed for a specific entity, provides a detailed explanation of the four-wheel drive system and how to handle it according to various terrains, along with the proper and safe use of supporting tools. It also covers potential mechanical failures encountered off-road, and ways to prevent and address them.",
            "Practical training in this program was conducted on a dedicated track in diverse terrains, using equipped vehicles and supported by an experienced team."
        ],
        location: "Amman, Jordan",
        date: "14 Aug 2022",
        duration: "5 Days",
    },
    {
        images: [prjImg7, prjImg8, prjImg9],
        title: "Finance for Non-Finance Manager",
        description: [
            "This program is considered unique and important, as its objectives are focused on the following:"
        ],
        sections: [
            {
                header: "Key Objectives",
                bulletPoints: [
                    "Use financial information to manage and assess your company or department operations.",
                    "Understand financial statements.",
                    "Analyze financial statements.",
                    "Measure performance.",
                    "Using finance to solve managerial problems.",
                    "How to manage working capital and estimate financial need.",
                    "Implementing advanced financial concepts in your managerial decision.",
                    "Get Confidence to prepare financial forecasts/budgets and read Management Information",
                ]
            }
        ],
        location: "Muscat, Sultanate of Oman",
        date: "14 Sep 2022",
        duration: "6 Days",
    },
    {
        images: [prjImg10, prjImg11, prjImg12],
        title: "Financial Accounting Skills",
        description: [
            "Financial Accounting is very essential for every staff worker in the Accounting, Treasury, Credit and Risk departments. Such training should bring key knowledge for the participants that will enable them to carry out their jobs properly.",
        ],
        sections: [
            {
                header: "This Program objectives are",
                bulletPoints: [
                    "Enable participants to explore the nature and environment of accounting, with special emphasis on the users and uses of accounting information.",
                    "Allow participants to understand the generally accepted accounting principles (GAAP).",
                    "Empower the participants understanding of the four financial statements",
                    "Qualify participants to measure business transactions and business income.",
                    "Enable participants to prepare the basic financial statements.",
                ]
            }
        ],
        location: "Amman, Jordan",
        date: "batch 1 13/9/2022, batch 2 20/9/2022",
        duration: "3 Days each",
    },
    {
        title: "Advanced PMP",
        description: [
            "The need for a continuous professional training in Project Management in most of contemporary organizations is of high importance due to the critical issues of project and business success, which is contingent on:",
        ],
        sections: [
            {
                header: "This Program objectives are",
                bulletPoints: [
                    "meeting business needs; aligned with business strategies",
                    "achieving project goals",
                    "maintaining project scope",
                    "meeting project deadlines",
                    "working within the project budget",
                    "controlling project progress and performance",
                    "considering risk throughout the project lifecycle",
                    "increasing resource efficiency",
                    "providing quality services of the whole project.",
                ]
            }
        ],
        location: "Muscat, Sultanate of Oman",
        date: "24 Oct 2022",
        duration: "4 Days ",
    },

    {
        title: "Brand Management",
        description: [
            "Mafaz conducted a Brand Management program that focused on key topics in branding within the Oil & Gas industry, highlighting its role, impact, and strategies to enhance brand identity and effectiveness."
        ],
        sections: [
            {
                header: "Branding in Oil & Gas",
                bulletPoints: [
                    "The importance of branding in the Oil & Gas industry",
                    "The role of branding in the Oil & Gas industry",
                    "Impact on recruitment and talent acquisition",
                    "Impact on digital transformation",
                    "Impact on public reputation and brand 'Goodwill'"
                ]
            },
            {
                header: "Branding & Company Image",
                bulletPoints: [
                    "Facts and figures about branding in the Oil & Gas industry",
                    "Anatomy of a winning brand in Oil & Gas"
                ]
            },
            {
                header: "How to Promote Your Brand / Products",
                bulletPoints: [
                    "Building your brand mix",
                    "Digital marketing",
                    "Non-digital marketing",
                    "Planning your branding strategy",
                    "Implementing your branding strategy",
                    "Assessing your branding strategy"
                ]
            }
        ],
        location: "Muscat, Sultanate of Oman",
        date: "14 Nov 2022",
        duration: "3 Days"
    }
    
];

// Preload images function
const preloadImages = (imageUrls) => {
    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
};

const Trainigprograms2022 = () => {
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
    const [mainImageIndex, setMainImageIndex] = useState(0);
    const [isFading, setIsFading] = useState(false);

    // Preload images on component mount
    useEffect(() => {
        const allImages = projects.flatMap((project) => project.images || []);
        preloadImages(allImages);
    }, []);

    const handlePreviousImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    prevIndex === 0 ? projects[currentProjectIndex].images.length - 1 : prevIndex - 1
                );
                setIsFading(false);
            }, 250); // Match the fade-out timing
        }
    };

    const handleNextImage = () => {
        if (!isFading) {
            setIsFading(true);
            setTimeout(() => {
                setMainImageIndex((prevIndex) =>
                    (prevIndex + 1) % projects[currentProjectIndex].images.length
                );
                setIsFading(false);
            }, 250); // Adjust to half the CSS transition duration
        }
    };

    const handlePreviousProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex === 0 ? projects.length - 1 : prevIndex - 1));
        setMainImageIndex(0);
    };

    const handleNextProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
        setMainImageIndex(0);
    };

    const { images, title, description, location, bulletPoints, sections, date, duration } =
        projects[currentProjectIndex];

    return (
        <section className="wpo-project-single-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="wpo-project-single-wrap">
                            <div className="wpo-project-single-content">
                                {/* Image Slider */}
                                <div
                                className={`main-image-container ${!images || images.length === 0 ? 'no-images' : ''}`}
                                style={!images || images.length === 0 ? { maxHeight: '0', marginBottom: '0' } : {}}
                            >
                                {images && images.length > 0 ? (
                                    <>
                                        <button className="slider-arrow left-arrow" onClick={handlePreviousImage}>
                                            &larr;
                                        </button>
                                        <img
                                            src={images[mainImageIndex]}
                                            alt={`Project Slide ${mainImageIndex + 1}`}
                                            className={`main-slider-image ${isFading ? 'fade-out' : 'fade-in'}`}
                                        />
                                        <button className="slider-arrow right-arrow" onClick={handleNextImage}>
                                            &rarr;
                                        </button>
                                    </>
                                ) : (
                                    <p style={{ textAlign: 'center', padding: '20px', color: '#555' }}>No images available</p>
                                )}
                            </div>


                                {/* Project Details */}
                                <div className="wpo-project-single-content-des">
                                    <div className="wpo-project-single-content-des-wrap">
                                        <div className="wpo-project-single-content-des-left">
                                            <h2>{title}</h2>

                                            {/* Render Descriptions */}
                                            {description &&
                                                description.map((para, index) => (
                                                    <p key={index} style={{ marginBottom: "15px", lineHeight: "1.8" }}>
                                                        {para}
                                                    </p>
                                                ))}

                                            {/* Render Bullet Points */}
                                            {bulletPoints && Array.isArray(bulletPoints) && (
                                                <ul
                                                    style={{
                                                        paddingLeft: "20px",
                                                        listStyleType: "disc",
                                                        color: "#333",
                                                        fontSize: "1rem",
                                                    }}
                                                >
                                                    {bulletPoints.map((point, index) =>
                                                        Array.isArray(point) ? (
                                                            <ul
                                                                key={index}
                                                                style={{
                                                                    paddingLeft: "20px",
                                                                    listStyleType: "circle",
                                                                }}
                                                            >
                                                                {point.map((subPoint, subIndex) => (
                                                                    <li
                                                                        key={subIndex}
                                                                        style={{
                                                                            marginBottom: "8px",
                                                                            lineHeight: "1.6",
                                                                        }}
                                                                    >
                                                                        {subPoint}
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <li
                                                                key={index}
                                                                style={{
                                                                    marginBottom: "8px",
                                                                    lineHeight: "1.6",
                                                                }}
                                                            >
                                                                {point}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            )}

                                            {/* Render Sections with Headers and Their Bullet Points */}
                                            {sections &&
                                                Array.isArray(sections) &&
                                                sections.map((section, sectionIndex) => (
                                                    <div key={sectionIndex} style={{ marginBottom: "20px" }}>
                                                        {/* Render Section Header */}
                                                        {section.header && (
                                                            <h3
                                                                style={{
                                                                    marginBottom: "10px",
                                                                    fontSize: "1.2rem",
                                                                    fontWeight: "bold",
                                                                    color: "#444",
                                                                }}
                                                            >
                                                                {section.header}
                                                            </h3>
                                                        )}
                                                        {/* Render Section Bullet Points */}
                                                        {Array.isArray(section.bulletPoints) &&
                                                            section.bulletPoints.length > 0 && (
                                                                <ul
                                                                    style={{
                                                                        paddingLeft: "20px",
                                                                        listStyleType: "disc",
                                                                        color: "#333",
                                                                        fontSize: "1rem",
                                                                    }}
                                                                >
                                                                    {section.bulletPoints.map((point, index) =>
                                                                        typeof point === "object" && point.subPoints ? (
                                                                            <li
                                                                                key={index}
                                                                                style={{
                                                                                    marginBottom: "8px",
                                                                                    lineHeight: "1.6",
                                                                                }}
                                                                            >
                                                                                {point.mainPoint}
                                                                                <ul
                                                                                    style={{
                                                                                        paddingLeft: "20px",
                                                                                        listStyleType: "circle",
                                                                                    }}
                                                                                >
                                                                                    {point.subPoints.map(
                                                                                        (subPoint, subIndex) => (
                                                                                            <li
                                                                                                key={subIndex}
                                                                                                style={{
                                                                                                    marginBottom: "8px",
                                                                                                    lineHeight: "1.6",
                                                                                                }}
                                                                                            >
                                                                                                {subPoint}
                                                                                            </li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            </li>
                                                                        ) : (
                                                                            <li
                                                                                key={index}
                                                                                style={{
                                                                                    marginBottom: "8px",
                                                                                    lineHeight: "1.6",
                                                                                }}
                                                                            >
                                                                                {point}
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            )}
                                                    </div>
                                                ))}
                                        </div>

                                        <div className="wpo-project-single-content-des-right">
                                            <ul>
                                                <li>
                                                    Location: <span>{location}</span>
                                                </li>
                                                <li>
                                                    Date: <span>{date}</span>
                                                </li>
                                                <li>
                                                    Duration: <span>{duration}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Stylish Navigation Buttons */}
                            <div className="more-posts">
                                <button onClick={handlePreviousProject} className="arrow-btn prev-btn">
                                    &larr; Previous Project
                                </button>
                                <button onClick={handleNextProject} className="arrow-btn next-btn">
                                    Next Project &rarr;
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <style>{`
    .main-image-container {
        position: relative;
        width: 100%;
        max-height: 500px;
        overflow: hidden;
        border-radius: 15px;
    }

    .main-image-container.no-images {
        max-height: 0;
        margin-bottom: 0;
    }

    .main-slider-image {
        width: 100%;
        height: 500px;
        object-fit: cover;
        border-radius: 15px;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
    }

    .main-slider-image.fade-out {
        opacity: 0;
    }

    .main-slider-image.fade-in {
        opacity: 1;
    }

    .wpo-project-single-content-des {
        margin-top: -20px; /* Adjust the margin as needed to reduce white space */
    }

    .slider-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        font-size: 18px;
        border-radius: 5px;
    }

    .slider-arrow.left-arrow {
        left: 10px;
    }

    .slider-arrow.right-arrow {
        right: 10px;
    }

    .slider-arrow:hover {
        background: rgba(0, 0, 0, 0.8);
    }

    .arrow-btn {
        background: linear-gradient(145deg, #003366, #004080); 
        color: white;
        border: none;
        padding: 12px 24px;
        border-radius: 25px;
        font-size: 16px;
        cursor: pointer;
    }

    .more-posts {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
    }
`}</style>

        </section>
    );
};

export default Trainigprograms2022;
