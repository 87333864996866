import React, {Fragment} from 'react';
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Trainigprograms2024 from '../../components/TrainingProgram2024';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const TrainingPrograms2024 =() => {
    return(
        <Fragment>
            <Navbar2/>
            <PageTitle pageTitle={'Training Programs 2024'} pagesub={'Services'}/> 
            <Trainigprograms2024/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default TrainingPrograms2024;